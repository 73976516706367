import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0,2,3,4];

export const dictionary = {
		"/": [~5],
		"/api": [6],
		"/esports/tournaments": [~7],
		"/esports/tournaments/[tournament_slug]": [~8,[2]],
		"/faq": [9],
		"/god/[god]": [~10],
		"/insights": [~11],
		"/leaderboard": [~12],
		"/leaderboard/[leaderboard_id]": [~13],
		"/lobbies": [~14,[3]],
		"/lobbies/customs": [~15,[3]],
		"/lobbies/open": [~16,[3]],
		"/profile/[profile_id]": [~17,[4]],
		"/tools": [18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';